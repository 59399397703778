@font-face {
    font-family: 'BroartFont';
    src:
            url('../fonts/broart_font-webfont.woff2') format('woff2'),
            url('../fonts/broart_font-webfont.woff') format('woff');
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

/* CUSTOM FONT*/
.font-icon {
    font-family: BroartFont, sans-serif ;
}

/* SELECT2 CUSTOM THEME*/

.select2-container--default .select2-selection--multiple {
    border: 1px solid rgba(0,0,0, .1) !important;
    border-radius: 0 !important;
}

.select2-dropdown {
    border: 1px solid rgba(0,0,0, .1) !important;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    display: inline-block !important;
    width: 100%;
    height: 27px !important;
    vertical-align: middle !important;
    font-size: 11px !important;
    font-weight: 400;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    color: #777 !important;
    border: 1px solid rgba(0,0,0, .1) !important;
    padding-left: 7px !important;;
    padding-right: 7px !important;
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000) !important;
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000) !important;
}

.select2-results__option {
    font-family: BroartFont, sans-serif;
}

.select2-selection__choice__display {
    font-family: BroartFont, sans-serif;
}

.wc-item-meta > li:nth-child(6) > p:nth-child(2) {
    font-family: BroartFont, sans-serif !important;
}

.wc-item-meta > li:nth-child(5) > p:nth-child(2) {
    font-family: BroartFont, sans-serif !important;
}

/* Override Bootstrap */
a.thumbnail:hover, a.thumbnail:active, a.thumbnail:focus {
    border-color: rgba(0,0,0, .7) !important
}
.carousel-control.right {
    background-image: linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.1) 100%) !important;
}

.carousel-control.left {
    background-image: linear-gradient(to right,rgba(0,0,0,.1) 0,rgba(0,0,0,.0001) 100%) !important;
}

/* Style custom */

#myModal {
    min-height: calc(100vh - 60px);
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

#myModal[style*="display: block"] {
    display: flex !important;
}


/* Selezione taglie */

.size-selector {
    font-size: 10px;
    font-family: "Open Sans", arial, sans-serif;
}

.size-selector .size-numbers {
    display: inline-block;
    min-width: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 4px 3px;
    border: 1px solid #eaeaea;
    color: #999;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.size-selector .size-numbers:hover {
    border-color: #bbb;
    color: #444;
    cursor: default;
}

.size-numbers.current, .size-numbers.current:hover {
    pointer-events: none;
    border-color: rgba(34,34,34, 1);
    background-color: rgba(34,34,34, .9);
    color: #fff;
}

.guidaTaglie {
    font-size: 10px;
    color: #777;
    float: right;
    cursor: pointer;
}

.guidaTaglie:hover, .guidaTaglie:active, .guidaTaglie:focus {
    color: #000;
    text-decoration: none;
}

div.product-addon-totals {
    margin:20px 0 40px 0;
    padding:20px 0 0 0;
    border-top:1px solid #eee;
    border-bottom:1px solid #eee;
    overflow:hidden
}
div.product-addon-totals ul {
    list-style:none;
    margin:0
}
div.product-addon-totals ul li {
    display:flex;
    justify-content:space-between;
    padding:5px 0
}
div.product-addon-totals ul li.wc-pao-row-quantity-based {
    padding-left:20px
}
div.product-addon-totals .wc-pao-subtotal-line {
    justify-content:flex-end;
    border-top:1px solid #eee;
    padding:10px 0;
    margin-top:20px
}
.single-product div.product div.product-addon-totals .wc-pao-subtotal-line p.price {
    margin:0
}

/* Responsive */

@media(max-width: 768px) {
    .modal-dialog {
        min-height: calc(100vh - 20px);
    }

    #galleria-ciondoli {
        display: none;
    }
}

/* Woocommerce style */

/* ==============================
Forms fields
============================== */
.form input[type="text"].input-md,
.banner-form.form input[type="text"],
.form input[type="email"].input-md,
.banner-form.form input[type="email"],
.form input[type="number"].input-md,
.form input[type="url"].input-md,
.form input[type="search"].input-md,
.form input[type="tel"].input-md,
.form input[type="password"].input-md,
.form input[type="date"].input-md,
.form input[type="color"].input-md,
.form select.input-md{
    height: 37px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 12px;
}
form{
    margin-bottom: 0;
}
.widget_wysija input[type="text"],
.wpcf7-form input[type="text"],
.wpcf7-form input[type="email"],
.wpcf7-form select,
.form input[type="text"],
.form input[type="email"],
.form input[type="number"],
.form input[type="url"],
.form input[type="search"],
.form input[type="tel"],
.form input[type="password"],
.form input[type="date"],
.form input[type="color"],
.form select,
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="number"],
.comment-form input[type="url"],
.comment-form input[type="search"],
.comment-form input[type="tel"],
.comment-form input[type="password"],
.comment-form input[type="date"],
.comment-form input[type="color"],
.comment-form select{
    display: inline-block;
    height: 27px;
    vertical-align: middle;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #777;
    border: 1px solid rgba(0,0,0, .1);
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.form select{
    font-size: 12px;
}
.wpcf7-form select {
    height: 37px;
    border-radius: 0 !important;
}
.form select[multiple]{
    height: auto;
}
.wpcf7-form input[type="text"].wpcf7-form-control:hover,
.wpcf7-form input[type="email"].wpcf7-form-control:hover,
.form input[type="text"]:hover,
.form input[type="email"]:hover,
.form input[type="number"]:hover,
.form input[type="url"]:hover,
.form input[type="search"]:hover,
.form input[type="tel"]:hover,
.form input[type="password"]:hover,
.form input[type="date"]:hover,
.form input[type="color"]:hover,
.form select:hover,
.comment-form input[type="text"]:hover,
.comment-form input[type="email"]:hover,
.comment-form input[type="number"]:hover,
.comment-form input[type="url"]:hover,
.comment-form input[type="search"]:hover,
.comment-form input[type="tel"]:hover,
.comment-form input[type="password"]:hover,
.comment-form input[type="date"]:hover,
.comment-form input[type="color"]:hover,
.comment-form select:hover{
    border-color: rgba(0,0,0, .2);
}
.wpcf7-form input[type="text"].wpcf7-form-control:focus,
.wpcf7-form input[type="email"].wpcf7-form-control:focus,
.widget_wysija input[type="text"].wysija-input:focus,
.form input[type="text"]:focus,
.form input[type="email"]:focus,
.form input[type="number"]:focus,
.form input[type="url"]:focus,
.form input[type="search"]:focus,
.form input[type="tel"]:focus,
.form input[type="password"]:focus,
.form input[type="date"]:focus,
.form input[type="color"]:focus,
.form select:focus,
.comment-form input[type="text"]:focus,
.comment-form input[type="email"]:focus,
.comment-form input[type="number"]:focus,
.comment-form input[type="url"]:focus,
.comment-form input[type="search"]:focus,
.comment-form input[type="tel"]:focus,
.comment-form input[type="password"]:focus,
.comment-form input[type="date"]:focus,
.comment-form input[type="color"]:focus,
.comment-form select:focus{
    color: #111;
    border-color: rgba(0,0,0, .7);
    -webkit-box-shadow: 0 0 3px rgba(0,0,0, .3);
    box-shadow: 0 0 3px rgba(0,0,0, .3);
    outline: none;
}
.form input[type="text"].input-lg,
.form input[type="email"].input-lg,
.form input[type="number"].input-lg,
.form input[type="url"].input-lg,
.form input[type="search"].input-lg,
.form input[type="tel"].input-lg,
.form input[type="password"].input-lg,
.form input[type="date"].input-lg,
.form input[type="color"].input-lg,
.form select.input-lg{
    height: 46px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
}
.widget_wysija input[type="text"].wysija-input,
.wpcf7-form input[type="text"].wpcf7-form-control,
.wpcf7-form input[type="email"].wpcf7-form-control,
.form input[type="text"].input-md,
.form input[type="email"].input-md,
.form input[type="number"].input-md,
.form input[type="url"].input-md,
.form input[type="search"].input-md,
.form input[type="tel"].input-md,
.form input[type="password"].input-md,
.form input[type="date"].input-md,
.form input[type="color"].input-md,
.form select.input-md,
.comment-form input[type="text"].input-md,
.comment-form input[type="email"].input-md,
.comment-form input[type="number"].input-md,
.comment-form input[type="url"].input-md,
.comment-form input[type="search"].input-md,
.comment-form input[type="tel"].input-md,
.comment-form input[type="password"].input-md,
.comment-form input[type="date"].input-md,
.comment-form input[type="color"].input-md,
.comment-form select.input-md{
    height: 37px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 12px;
}

.wpcf7-form input[type="text"].wpcf7-form-control.input-lg,
.wpcf7-form .wpcf7-form-control.input-lg,
.wpcf7-form input[type="email"].wpcf7-form-control.input-lg {
    font-size: 13px;
    height: 46px;
    padding-left: 15px;
    padding-right: 15px;
}

.form input[type="text"].input-sm,
.form input[type="email"].input-sm,
.form input[type="number"].input-sm,
.form input[type="url"].input-sm,
.form input[type="search"].input-sm,
.form input[type="tel"].input-sm,
.form input[type="password"].input-sm,
.form input[type="date"].input-sm,
.form input[type="color"].input-sm,
.form select.input-sm{
    height: 31px;
    padding-left: 10px;
    padding-right: 10px;
}
.form textarea,
.comment-form textarea,
.wpcf7-form textarea{
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    color: #777;
    border-color: rgba(0,0,0, .1);
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}
.form textarea:hover,
.comment-form textarea:hover,
.wpcf7-form textarea:hover{
    border-color: rgba(0,0,0, .2);
}
.form textarea:focus,
.comment-form textarea:focus,
.wpcf7-form textarea:focus{
    color: #111;
    border-color: rgba(0,0,0, .7);
    -webkit-box-shadow: 0 0 3px rgba(21,21,21, .3);
    box-shadow: 0 0 3px rgba(21,21,21, .3);
}

.form .form-group{
    margin-bottom: 10px;
}
.form-tip{
    font-size: 11px;
    color: #aaa;
}
.form-tip .fa{
    font-size: 12px;
}

.search-wrap{
    position: relative;
}
.search-button{
    width: 42px;
    height: 40px;
    line-height: 38px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    right: 1px;
    overflow: hidden;
    background: transparent;
    border: none;
    outline: none;
    color: #999;
    font-size: 14px;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.search-field{
    width: 100% !important;
    height: 40px !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.banner-form.form .wpcf7-submit {
    background: transparent;
    border:2px solid rgba(255,255,255, .75);
    padding:8px 37px;
    height: auto;
    width: 100%;
    color:#fff;
}
.banner-form.form .wpcf7-submit:hover {
    background: rgba(255,255,255,1);
    color:#111;
}
.wpcf7-form-control.wpcf7-submit,
.wysija-submit,
.comment-form input[type=submit],
.btn-mod,
a.btn-mod{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 4px 13px;
    color: #fff;
    background: rgba(34,34,34, .9);
    border: 2px solid transparent;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    border-radius: 0;

    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.wysija-submit:hover,
.wysija-submit:focus,
.wpcf7-form-control.wpcf7-submit:hover,
.wpcf7-form-control.wpcf7-submit:focus,
.comment-form input[type=submit],
.btn-mod:hover,
.btn-mod:focus,
a.btn-mod:hover,
a.btn-mod:focus{
    font-weight: 400;
    color: rgba(255,255,255, .85);
    background: rgba(0,0,0, .7);
    text-decoration: none;
    outline: none;
}
.btn-mod:active{
    cursor: pointer !important;
}

.btn-mod.btn-small{
    padding: 6px 17px;
    font-size: 11px;
    letter-spacing: 1px;
}
.comment-form input[type=submit],
.btn-mod.btn-medium,
.wysija-submit, .wpcf7-form-control.wpcf7-submit {
    padding: 7px 37px;
    font-size: 12px;
    border: none;
}

.wysija-submit { padding: 8px 37px; }

.btn-mod.btn-medium.btn-cta2 {
    padding: 8px 37px;
    border: 2px solid rgba(34, 34, 34, 0.901961);
}

.btn-mod.btn-medium{
    padding: 10px 39px;
}
.btn-mod.btn-medium.btn-border, .btn-mod.btn-medium.btn-border-w{
    padding: 8px 37px;
}
.comment-form input[type=submit]{
    background: rgba(34,34,34, .9);
}
.comment-form input[type=submit]:hover{
    background: rgba(34,34,34, .8);
}
.btn-mod.btn-large{
    padding: 12px 45px;
    font-size: 13px;
    height: auto;
}
.btn-mod.btn-large-alt {
    padding: 10px 40px;
    font-weight: 700;
}
.btn-mod.btn-large-alt:hover {
    font-weight: 700;
}

.btn-mod.btn-glass{
    color: rgba(255,255,255, .75);
    background: rgba(0,0,0, .40);
}
.btn-mod.btn-glass:hover,
.btn-mod.btn-glass:focus{
    color: rgba(255,255,255, 1);
    background: rgba(0,0,0, 1);
}

.btn-mod.btn-border{
    color: #151515;
    border: 2px solid #151515;
    background: transparent;
}
.btn-mod.btn-border:hover,
.btn-mod.btn-border:focus{
    color: #fff;
    border-color: transparent;
    background: #000;
}

.btn-mod.btn-border-c{
    color: #e41919;
    border: 2px solid #e41919;
    background: transparent;
}
.btn-mod.btn-border-c:hover,
.btn-mod.btn-border-c:focus{
    color: #fff;
    border-color: transparent;
    background: #e41919;
}

.btn-mod.btn-border-w{
    color: #fff;
    border: 2px solid rgba(255,255,255, .75);
    background: transparent;
}
.btn-mod.btn-border-w:hover,
.btn-mod.btn-border-w:focus{
    color: #111;
    border-color: transparent;
    background: #fff;
}

.btn-mod.btn-w,
.btn-nws-grey .wysija-submit{
    color: #111;
    background: rgba(255,255,255, .8);
}
.btn-mod.btn-w:hover,
.btn-mod.btn-w:focus,
.btn-nws-grey .wysija-submit:hover,
.btn-nws-grey .wysija-submit:focus{
    color: #111;
    background: #fff;
}

.btn-mod.btn-w-color{
    color: #e41919;
    background: #fff;
}
.btn-mod.btn-w-color:hover,
.btn-mod.btn-w-color:focus{
    color: #151515;
    background: #fff;
}

.btn-mod.btn-gray{
    color: #777;
    background: #e5e5e5;
}
.btn-mod.btn-gray:hover,
.btn-mod.btn-gray:focus{
    color: #444;
    background: #d5d5d5;
}

.btn-mod.btn-color{
    color: #fff;
    background: #e41919;
}
.btn-mod.btn-color:hover,
.btn-mod.btn-color:focus{
    color: #fff;
    background: #e41919;
    opacity: .85;
}

.btn-mod.btn-blue {
    background-color: #17c2d6;
    color: #fff;
}

.btn-mod.btn-circle{
    border-radius: 30px;
}
.btn-mod.btn-round,
.wpcf7-form-control.wpcf7-submit{
    border-radius: 2px;
}
.btn-icon{
    position: relative;
    border: none;
    overflow: hidden;
}
.btn-icon.btn-small{
    overflow: hidden;
}
.btn-icon > span{
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    left: 0;
    color: #777;
    font-size: 48px;
    opacity: .2;

    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.btn-icon:hover > span{
    opacity: 0;

    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
}
.btn-icon > span.white{
    color: #fff;
}
.btn-icon > span.black{
    color: #000;
}
.btn-full{
    width: 100%;
}